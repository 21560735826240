import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/common/Layout';
import MyAppListing from '../../components/AppListing';
import Container from '../../components/common/Container';

const AppListingPage = () => (
  <Layout lang="de">
    <SEO
      title="Welche Apps und Versionen von Nextcloud können bei Portknox verwendet werden?"
      description="Hier sind die installierbaren Apps und ihrer Versionen aufgelistet."
    />
    <Container>
      <MyAppListing />
    </Container>
  </Layout>
);

export default AppListingPage;
